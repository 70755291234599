<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")

        div(class="schefule-header") >>
          span(class="display-title") 配信スケジュール

        div(v-for="item in list" class="schefule-content")
          div(class="schefule-content-item")
            div {{ item.schedule_start_time | moment("YYYY/MM/DD HH:mm") }} ～ {{ (item.schedule_end_time?item.schedule_end_time:undefined) | moment("YYYY/MM/DD HH:mm") }}
          div(class="schefule-content-item")
            div(v-if="item.liveable_type === 'NORMAL'" @click="onEdit(item)" class="link-color") 変更
            div(v-if="item.liveable_type === 'NORMAL'" @click="onScheduleDelete(item)" class="link-color") 削除
            div(v-if="item.liveable_type === 'PAY'" class="pink-r-label") 有料配信
            div(v-if="item.liveable_type === 'FUND'" class="pink-r-label") ｸﾗﾌｧﾝ配信
            div(v-if="item.liveable_type === 'PAY' || item.liveable_type === 'FUND'" @click="onDetail(item.id, item.liveable_id, item.liveable_type, item.profile.account_name)" class="link-color") 詳細

        div(@click="onCreate()" class="link-color make-schedule") >>スケジュールを作成

      div(v-if="ui.status === 0")
        pr-banner
      div(v-else class="content-right")
        div(class="right-content-border")
          div(v-if="ui.status === 1 || ui.status === 2")
            button(@click="onClose()" class="close-button") <i class="fas fa-times"></i>
            div 配信開始予定日時
            input(type="datetime-local" v-model="date" class="input-g-w-border")
            div(class="caution-t") ※イベント参加中はスケジュール作成の有無にかかわらず1日2回以上配信をすることはできません
            button(v-if="ui.status === 1" @click="onCreateConfirm()" class="pink-button") 登録する
            img(v-else-if="ui.status !== 1" @click="onEditConfirm()" class="img-button" src="/img/henkou_p.png")
          div(v-if="ui.status === 3" class="schedule_detail")
            button(@click="onClose()" class="close-button") <i class="fas fa-times"></i>
            img(:src="$store.state.system.config.storage_addr_list.public + banner_store_path")
            div {{ title }}
            div {{ account_name }}
            div {{ edit.schedule_start_time | moment("YYYY年MM月DD日 HH:mm") }} ～ {{ (edit.schedule_end_time?edit.schedule_end_time:undefined) | moment("YYYY年MM月DD日 HH:mm") }}（予定）
            div {{ describe }}
            div {{ price }}（Gのみ）
          div(v-if="ui.status === 4")
            button(@click="onClose()" class="close-button") <i class="fas fa-times"></i>
            div(class="confirm") 配信スケジュールを削除しますか？
              img(@click="onDelete(data)" class="img-button" src="/img/yes_p.png")
              img(@click="onCancel()" class="img-button" src="/img/no_p.png")

    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { createLiveNormal, setLiveNormalS1, getLiveAllListS1, deleteLiveNormalS1, getLivePayDetail } from '@/api/live'
import moment from 'moment';

export default {
  name: "SettingScheduleConfirmIndex",
  components: {},
  data() {
    return {
      onScheduleDeleteStatus: false,
      ui: {
        // 0: pr
        // 1: create
        // 2: edit
        // 3: detail
        status: 0
      },
      editNow: undefined,
      date: undefined,
      edit: undefined,
      title: undefined,
      describe: undefined,
      account_name: undefined,
      banner_store_path: undefined,
      price: undefined,
      list: [],
      data: undefined
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      const { data } = await getLiveAllListS1();
      this.list = data.data;
    },
    onEdit(item) {
      this.editNow = item;
      // this.editNow = moment(schedule_start_time);
      this.date = moment(item.schedule_start_time).format("yyyy-MM-DDTHH:mm") ;
      this.ui.status = 2;
    },
    onScheduleDelete(item){
      this.data = item
      this.ui.status = 4;
    },
    onCancel() {
      this.onScheduleDeleteStatus = true;
      this.ui.status = 0;
    },
    async onDelete(item) {
      await deleteLiveNormalS1(item.liveable_id);
      this.$message.success("削除しました");
      await this.init();
      this.ui.status = 0;
    },
    async onDetail(id, liveable_id, type, account_name) {
      if (type === 'FUND') {
        this.$router.push({ name: 'SettingClubfunDetailIndex', params: {liveable_id: liveable_id} })
      } else {
        const { data } = await getLivePayDetail(liveable_id);
        this.banner_store_path = data.banner_store_path;
        this.title = data.title;
        this.describe = data.describe;
        this.account_name = account_name;
        this.price = data.price;
        this.ui.status = 3;
        this.edit = this.list.filter(v => v.id === id)[0];
      }
    },
    onClose() {
      this.ui.status = 0;
    },
    onCreate() {
      this.ui.status = 1;
    },
    async onCreateConfirm() {
      if (this.date) {
        const { data } = await createLiveNormal();
        try{
          await setLiveNormalS1(data.id, this.date);
        }catch(e) {
          console.error(e);
        }
        this.$message.success("登録完了しました");
        await this.init();
        this.ui.status = 0;
      }
    },
    async onEditConfirm() {
      await setLiveNormalS1(this.editNow.liveable_id, this.date);
      await this.init();
      this.$message.success("変更完了しました");
      this.ui.status = 0;
    },
  }
};
</script>
